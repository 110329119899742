import { render, staticRenderFns } from "./Canzone.vue?vue&type=template&id=7ace0592&scoped=true&"
import script from "./Canzone.vue?vue&type=script&lang=js&"
export * from "./Canzone.vue?vue&type=script&lang=js&"
import style0 from "./Canzone.vue?vue&type=style&index=0&id=7ace0592&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ace0592",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})
