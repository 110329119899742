<template>
    <div class="canzone" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'phone' : ''">
        <v-progress-circular style="margin-top: 5rem; align-self: center" v-if="!canzone" :size="30" :width="4" color="black" indeterminate></v-progress-circular>
        <div class="cntCanzone" v-else>
            <h2>{{ canzone.Titolo }}</h2>
            <p>
                <strong>{{ canzone.Autore }}</strong>
            </p>
            <p style="white-space: pre-line">{{ !traduzione ? canzone.Testo : canzone.Traduzione }}</p>
        </div>
    </div>
</template>

<script>
import googleSheets from "../scripts/googleSheets";
export default {
    props: ["title", "traduzione"],

    async mounted() {
        this.canzone = await googleSheets.getCanzone(this.title);
        this.traduzione
    },

    data() {
        return {
            canzone: null
        };
    }
};
</script>

<style lang="scss" scoped>
.canzone {
    background: linear-gradient(180deg, rgb(129, 148, 255) 0%, rgb(166, 198, 245) 35%, rgb(191, 224, 255) 100%);
    height: 100%;
    display: flex;
    flex-direction: column;
    .cntCanzone {
        margin-top: 50px;
        padding: 1.5rem;
        margin-bottom: 30px;
    }
}
</style>
